/* tslint:disable */
/* eslint-disable */
export enum SkipJobType {
  DropOff = 'DropOff',
  Renew = 'Renew',
  Move = 'Move',
  Empty = 'Empty',
  LoadMaterial = 'LoadMaterial',
  DropOffMaterial = 'DropOffMaterial',
  Pickup = 'Pickup'
}
