/* tslint:disable */
/* eslint-disable */
export enum SkipType {
  RollContainer10 = 'RollContainer10',
  RollContainer24 = 'RollContainer24',
  RollContainer30 = 'RollContainer30',
  RollContainer35 = 'RollContainer35',
  RollOff = 'RollOff',
  ThermalSilo = 'ThermalSilo',
  Standard100 = 'Standard100',
  Lid200 = 'Lid200',
  Standard300 = 'Standard300',
  Bulk400 = 'Bulk400',
  Flat500 = 'Flat500',
  Lid600 = 'Lid600',
  Sorling = 'S\xF6rling',
  Mischer = 'Mischer'
}
