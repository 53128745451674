/* tslint:disable */
/* eslint-disable */
export enum SupportPlateType {
  Kranholz = 'Kranholz',
  Platten90Er = 'Platten90er',
  Platten100Er = 'Platten100er',
  Platten130Er = 'Platten130er',
  Platten160Er = 'Platten160er',
  Platten250Er = 'Platten250er',
  Platten300Er = 'Platten300er',
  GipoPlatten = 'GipoPlatten',
  Stahltragerplatten = 'Stahltr\xE4gerplatten',
  BarelleMitabstutzholz = 'BarelleMitabst\xFCtzholz'
}
