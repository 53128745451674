import { commonEnvironment } from './common.environment';

export const environment = {
  ...commonEnvironment,
  production: true,
  showCreatePdfButton: false,
  preventFormUnload: true,
  sentry:
    'https://https://b3bdfa1f03474725bbc6b7a53bb08881@o296516.ingest.sentry.io/6520238',
  envName: 'prod',
  serverApiUrl: 'https://cal.christen.ch',
  authServerUrl: 'https://auth.devcloud.triarc-labs.com/auth',
  redirectUrl: 'https://digital.christen-ag.ch/login',
};
