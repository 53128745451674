/* tslint:disable */
/* eslint-disable */
export enum ProductUnit {
  Kg = 'Kg',
  Cubic = 'Cubic',
  Pieces = 'Pieces',
  To = 'To',
  Time = 'Time',
  Transport = 'Transport'
}
