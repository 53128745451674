/* tslint:disable */
/* eslint-disable */
export enum AbacusUnit {
  Stk = 'Stk',
  Kg = 'Kg',
  M = 'M',
  Std = 'Std',
  Le = 'LE',
  Lt = 'Lt',
  H = 'H',
  Eht = 'Eht',
  Chf = 'Chf',
  Mt = 'Mt',
  Km = 'Km',
  D = 'D',
  Gl = 'Gl',
  M3 = 'M3',
  Pl = 'Pl',
  Fr = 'Fr',
  To = 'To',
  Cm = 'Cm',
  Mm = 'Mm',
  M2 = 'M2',
  Jahr = 'Jahr',
  Percent = 'Percent',
  Fahrt = 'Fahrt',
  Trsp = 'Trsp',
  Q = 'Q',
  Tkm = 'Tkm',
  Min = 'Min'
}
