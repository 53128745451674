/* tslint:disable */
/* eslint-disable */
export enum ReportStatus {
  Created = 'Created',
  Signed = 'Signed',
  Completed = 'Completed',
  Verified = 'Verified',
  Approved = 'Approved',
  SubmittedSuccess = 'SubmittedSuccess',
  SubmittedFailure = 'SubmittedFailure',
  WaitingForDeliverySlips = 'WaitingForDeliverySlips'
}
