/* tslint:disable */
/* eslint-disable */
export enum TaskType {
  Winterdienst = 'Winterdienst',
  LkwKran = 'LkwKran',
  MobilKran = 'MobilKran',
  Kipper = 'Kipper',
  Mischer = 'Mischer',
  Schlepper = 'Schlepper',
  FuhrleistungIntern = 'FuhrleistungIntern',
  FuhrleistungExtern = 'FuhrleistungExtern',
  Hakengerat = 'Hakenger\xE4t',
  InstallationsPauschale = 'InstallationsPauschale',
  Hebeleistung = 'Hebeleistung'
}
