/* tslint:disable */
/* eslint-disable */
export enum BillingType {
  Time = 'Time',
  Amount = 'Amount',
  Fixed = 'Fixed',
  FixedDay = 'FixedDay',
  FixedTrip = 'FixedTrip',
  FixedTimeRate = 'FixedTimeRate'
}
